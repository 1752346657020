import Technology from "../../component/common/Technology/Technology";
import AboutBanner from "./AboutBanner/AboutBanner";
import OurMission from "./OurMission/OurMission";

const About = () => {
  return (
    <>
      <AboutBanner />
      <OurMission />
      <Technology />
    </>
  );
};

export default About;
