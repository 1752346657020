export const tokenValues = {
  link: "0x97d45Ec651d25b90701ec49c959E75efe4E1F36c",
  link2:
    "https://quickswap.exchange/#/swap?currency0=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&currency1=0x97d45Ec651d25b90701ec49c959E75efe4E1F36c&swapIndex=1&isProMode=false",
  price: 0.37,
  lowHour: 0.1,
  highHour: 0.37,
  lowDay: 0.1,
  highDay: 0.37,
  volume: 268.076,
  high: 0.54,
  low: 0.1,
};
