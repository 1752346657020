import link from "./types";

export const defaultNavigationData: link[] = [
  {
    id: "navigation_home",
    title: "Home",
    path: "/",
    selected: false,
  },
  {
    id: "navigation_about_us",
    title: "About Us",
    path: "/about-us",
    selected: false,
  },
  {
    id: "navigation_whitepaper",
    title: "Whitepaper",
    path: "/whitepaper",
    selected: false,
  },
  {
    id: "navigation_team",
    title: "Team",
    path: "/team",
    selected: false,
  },
  {
    id: "navigation_nftx7",
    title: "NFTX7",
    external_link: true,
    path: "https://nftx7.io/about",
    selected: false,
  },
];
