import TokenDescription from "./TokenDescription/TokenDescription";
import TokenInformation from "./TokenInformation/TokenInformation";
function Home() {
  return (
    <>
      <TokenDescription />
      <TokenInformation />
    </>
  );
}

export default Home;
