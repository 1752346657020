import "./style.css";

const Technology = () => {
  return (
    <section id="technology-behind-nx7">
      <div className="container">
        <div className="content">
          <h2 className="section-heading">The Technology behind NX7 Token</h2>
          <p className="text-justified">
            Using NFTs allows for a crowd-funded method of starting a trading
            portfolio. The speed and high liquidity of NFTs make them an ideal
            choice for this project. Our deflationary token model ensures that
            the value of the token can appreciate, which may lead to an increase
            in the value of the rewards.
          </p>
        </div>
        <img src="assets/images/common/nx7-technology.png" alt="Technology" />
      </div>
    </section>
  );
};

export default Technology;
