import "./style.css";

const OurMission = () => {
  return (
    <section id="about-mission" className="page-section container">
      <div className="image-container">
        <img src="/assets/images/pages/about/nx7-token.gif" alt="NX7-token" />
      </div>
      <article>
        <h2 className="section-heading">Our Mission</h2>
        <p className="text-justified">
          NX7 is a deflationary ecosystem that maintains its value by
          incentivizing and fueling transactions. Its primary utility is to pay
          NFTX7 holders a fixed-value interest, and token holders can utilize it
          to transfer wealth and value among themselves. The ecosystem has a
          built-in volume that fuels transactions and a deflationary model that
          can mathematically increase its value due to the burning effect and
          the automated market maker model, which defines the balancing method
          for pricing tokens.
        </p>
      </article>
    </section>
  );
};

export default OurMission;
