import { useState } from "react";
import { tokenValues } from "./TokenInfoBox/data";
import CopySvg from "./TokenInfoBox/CopySvg";

const TokenInformation = () => {
  const [showTooltip, setShowToolTip] = useState(false);
  const linkWithoutHttp = tokenValues.link
    .replace("https://www.", "")
    .replace(".com", "");
  const handleCopy = () => {
    setShowToolTip(true);
    navigator.clipboard.writeText(linkWithoutHttp);
    setTimeout(function () {
      setShowToolTip(false);
    }, 1000);
  };
  return (
    <section id="home-token-information" className="page-section container">
      <div className="mb-10 flex flex-col justify-between gap-2 md:flex-row">
        <span className="text-3xl">NX7 Token Contract</span>
        <span
          onClick={() => handleCopy()}
          className="flex cursor-pointer gap-4 text-2xl"
        >
          {`${linkWithoutHttp.slice(0, 7)}...${linkWithoutHttp.slice(-5)}`}
          {showTooltip && (
            <span id="custom-tooltip" className="absolute translate-y-full">
              Link Copied!
            </span>
          )}
          <CopySvg />
        </span>
      </div>
      <iframe
        className="rounded-[50px]"
        height="500px"
        width="100%"
        id="geckoterminal-embed"
        title="GeckoTerminal Embed"
        src="https://www.geckoterminal.com/polygon_pos/pools/0xe859eeda809a6352b151c41c5332922a586aaf84?embed=1&info=0&swaps=0"
        allow="clipboard-write"
      ></iframe>
      {/* <TokenInfoBox /> */}
    </section>
  );
};

export default TokenInformation;
