import { ButtonProps } from "./types";
import "./style.css";
const PrimaryButton = ({ text, additionalClass, onClick }: ButtonProps) => {
  return (
    <button
      type="button"
      className={`primary-button ${additionalClass}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
