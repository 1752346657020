import "./style.css";

const CreatedBy = () => {
  return (
    <section id="team-created" className="page-section">
      <h2 className="section-heading">Created By</h2>
      <img
        className="mx-auto"
        src="/assets/images/logo/digital-x.png"
        alt="Digital-X"
      />
    </section>
  );
};

export default CreatedBy;
