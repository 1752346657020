import "./style.css";
import { defaultData as teamData } from "./data";

const MeetOurTeam = () => {
  return (
    <section id="team-meet" className="container page-section">
      <h2 className="section-heading">Meet our team</h2>
      <div id="team-container">
        {teamData.map((team) => {
          return (
            <article key={team.id}>
              <img src={team.image} alt={team.title} />
              <h3>{team.title}</h3>
              <p className="text-justified">{team.description}</p>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default MeetOurTeam;
