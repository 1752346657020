export const defaultData = [
  {
    id: 1,
    category: "Team",
    percentage: 15,
    amount: 750000.0,
    value: 75000.0,
  },
  {
    id: 2,
    category: "Ecosystem/Expense",
    percentage: 25,
    amount: 1250000.0,
    value: 125000.0,
  },
  {
    id: 3,
    category: "Advisory",
    percentage: 5,
    amount: 250000.0,
    value: 25000.0,
  },
  {
    id: 4,
    category: "Liquidity",
    percentage: 25,
    amount: 1250000.0,
    value: 125000.0,
  },
  {
    id: 5,
    category: "Future Collateral",
    percentage: 15,
    amount: 750000.0,
    value: 75000.0,
  },
  {
    id: 6,
    category: "Future Collateral",
    percentage: 15,
    amount: 750000.0,
    value: 75000.0,
  },
];
