import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../component/layout/Footer/Footer";
import Header from "../component/layout/Header/Header";
import About from "../pages/About/About";
import Unauthorized from "../pages/ErrorHandeling/401";
import PageNotFound from "../pages/ErrorHandeling/404";
import Home from "../pages/Home/Home";
import Team from "../pages/Team/Team";
import Whitepaper from "../pages/Whitepaper/Whitepaper";

function AppRouter() {
  return (
    <BrowserRouter>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/whitepaper" element={<Whitepaper />} />
          <Route path="401" element={<Unauthorized />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default AppRouter;
