export const defaultNavigationData = [
  {
    id: "navigation_home",
    title: "Home",
    path: "/",
    selected: false,
  },
  {
    id: "navigation_about_us",
    title: "About Us",
    path: "/about-us",
    selected: false,
  },
  {
    id: "navigation_whitepaper",
    title: "Whitepaper",
    path: "/whitepaper",
    selected: false,
  },
  {
    id: "navigation_team",
    title: "Team",
    path: "/team",
    selected: false,
  },
  {
    id: "navigation_nftx7",
    title: "NFTX7",
    external_link: true,
    path: "https://nftx7.io/about",
    selected: false,
  },
  {
    id: "navigation_community",
    title: "Community",
    path: "no-path",
    selected: false,
  },
];

export const socialIcons = [
  {
    id: "social_discord",
    title: "Discord",
    url: "https://discord.gg/feyEK2z7n7",
  },
  {
    id: "social_twitter",
    title: "Twitter",
    url: "https://twitter.com/NX7Token",
  },
  {
    id: "social_instagram",
    title: "Instagram",
    url: "https://www.instagram.com/nx7token/",
  },
  {
    id: "social_telegram",
    title: "Telegram",
    url: "https://t.me/NFTX7NX7",
  },
];
