export const defaultfaqs = [
  {
    id: 1,
    title: "What is an NFT?",
    text: "An NFT, or Non-Fungible Token, is a unique type of digital asset that cannot be replaced or exchanged for anything else. These tokens are stored on a blockchain, which is a decentralized digital ledger, and can represent various forms of digital assets such as art, music, videos, and other creative content. Unlike fungible tokens, like cryptocurrencies, each NFT is unique and has its own distinct characteristics and value. The use of NFTs has opened up new possibilities for creators to monetize their work and for collectors to own and trade unique digital assets.",
  },
  {
    id: 2,
    title: "How does the NX7 Token project work?",
    text: "The NX7 Token project is a crowd-funded initiative that uses NFTs to raise funds for a trading portfolio. The project allows individuals to purchase an NFT for a fixed price of $100 in Matic, which can then be staked to earn rewards of 1% of the nominal price of the NFT every 7 days. These rewards are paid out in the form of NX7 Tokens, which have been designed specifically for the project. The funds raised from the sale of the NFTs will be used to invest in trading, and the creators of the NFTs commit to rebuying any NFT at its nominal value of $100 at any time.",
  },
  {
    id: 3,
    title: "What are the benefits of owning an NX7 Token?",
    text: "Owning an NX7 Token provides several benefits. First, it allows individuals to support a crowd-funded project aimed at generating profits through trading investments. Second, staking an NFT allows owners to earn rewards in the form of NX7 Tokens, which can be withdrawn at any time. Finally, the creators of the NFTs commit to rebuying them at their nominal value, providing a level of security for NFT owners.",
  },
  {
    id: 4,
    title: "How can I purchase an NX7 Token NFT?",
    text: "To purchase an NX7 Token NFT, you can visit the project's website and follow the instructions for purchasing an NFT using Matic. Once the purchase is complete, the NFT will be deposited into your wallet, and you can then stake it to start earning rewards.",
  },
  {
    id: 5,
    title: "Can I sell my NX7 Token NFT?",
    text: "Yes, you can sell your NX7 Token NFT on Opensea, a popular marketplace for buying and selling NFTs. The creators of the NFTs have set a royalty of 10%, which means that they will receive 10% of the sale price every time the NFT is resold on the platform.",
  },
  {
    id: 6,
    title: "How do I stake my NX7 Token NFT to earn rewards?",
    text: "To stake your NX7 Token NFT, you will need to visit the project's website and follow the instructions for staking your NFT. Once your NFT is staked, you will start earning rewards of 1% of the nominal price of the NFT every 7 days, which will be deposited into your wallet automatically.",
  },
  {
    id: 7,
    title: "When will I receive my rewards for staking my NFT?",
    text: "You will receive your rewards for staking your NFT at the end of each cycle of 7 days. The rewards will be deposited into your wallet automatically, and you can withdraw them at any time.",
  },
  {
    id: 8,
    title: "How are the rewards calculated?",
    text: "The rewards are calculated as 1% of the nominal price of the NFT every 7 days. For example, if you purchased an NFT for $100, you would earn rewards of $1 every 7 days.",
  },
  {
    id: 9,
    title: "Can I withdraw my rewards at any time?",
    text: "Yes, you can withdraw your rewards at any time by visiting the project's website and following the instructions for withdrawing your rewards, and the rewards will be deposited automatically in your wallet.",
  },
  {
    id: 10,
    title: "How often will the rewards be paid out?",
    text: "The rewards will be paid out at the end of every cycle of 7 days.",
  },
  {
    id: 11,
    title: "What happens if the NX7 Token price fluctuates?",
    text: "Regardless of the fluctuation of the token's price, the reward at the end of every cycle of 7 days will be equal to 1$, i.e. 1% of the nominal price, minting price, at which the NFT was originally purchased. Token price can fluctuate, mainly appreciate, if the tokens paid from the rewards are held, or due to speculators activity.",
  },
  {
    id: 12,
    title: "What is the deflationary model and how does it work?",
    text: "The deflationary model means that the total supply of NX7 Tokens will decrease over time due to a burning mechanism. A small percentage of each transaction involving NX7 Tokens will be burned, reducing the overall supply of tokens and increasing their value over time.",
  },
  {
    id: 13,
    title: "Can I sell my NFT on Opensea?",
    text: "Yes, you can sell your NFT on Opensea and receive a royalty of 10%. If the NFT is sold for a higher price than its original minting price, you will generate extra profits. If not, the creators of the NFT are willing to rebuy it at its nominal value of 100$.",
  },
  {
    id: 14,
    title: "What will the funds raised from the NFT sales be used for?",
    text: "The funds raised from the sale of the NFTs will be used for trading investment, which will generate the rewards that are paid out to NFT owners. The trading wallet will be secure and transparent, and the performance of the trading strategy will be regularly updated for all participants to see.",
  },
  {
    id: 15,
    title:
      "What happens if the trading strategy underperforms? How often will the rewards be paid out?",
    text: "Although we are confident in our trading strategy, in the unlikely event of underperformance, the creators are willing to cover any gap by injecting their own funds. Additionally, if the creators decide to exit the project, they commit to returning the funds to the stakers/NFT owners. However, we believe that the investment strategy will generate profits and ensure a sustainable rewards program for our community of stakers and NFT owners.",
  },
];
