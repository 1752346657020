import { useState } from "react";
import { SocialDetail } from "./types";
import { icons } from "./data";
import "./style.css";

const SocialIcon = (Props: SocialDetail) => {
  const { title, url } = Props;
  const [iconPath] = useState(icons);

  return (
    <>
      {url ? (
        <a
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          className="social-bubble"
        >
          <img src={iconPath.get(title)} alt={`${title}-svg`} />
        </a>
      ) : (
        <div className="social-bubble">
          <img src={iconPath.get(title)} alt={`${title}-svg`} />
        </div>
      )}
    </>
  );
};

export default SocialIcon;
