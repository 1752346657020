import PrimaryButton from "../../../component/common/PrimaryButton/PrimaryButton";
import "./style.css";

const TokenDescription = () => {
  return (
    <section id="home-token-description">
      <img
        className="hidden lg:block"
        src="/assets/images/pages/home/banner-image.jpg"
        alt="Home Banner"
      />
      <img
        className="lg:hidden"
        src="/assets/images/pages/home/banner-image-mobile.jpg"
        alt="Home Banner Mobile"
      />
      <div className="container absolute bottom-16 left-[6.5%] flex max-h-16 gap-5">
        <PrimaryButton
          additionalClass="whitespace-nowrap"
          text="Buy NX7 Tokens"
          onClick={() => {
            window.open(
              "https://quickswap.exchange/#/swap?currency0=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&currency1=0x97d45Ec651d25b90701ec49c959E75efe4E1F36c&swapIndex=1&isProMode=false",
            );
          }}
        />
        <img
          className="max-h-16 max-w-[64px]"
          onClick={() =>
            window.open(
              "https://quickswap.exchange/#/swap?currency0=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&currency1=0x97d45Ec651d25b90701ec49c959E75efe4E1F36c&swapIndex=1&isProMode=false",
            )
          }
          src="/assets/images/logo/quickswap.png"
          alt="Quickswap"
        />
      </div>
    </section>
  );
};

export default TokenDescription;
