import "./style.css";
import WhitePaperAnchors from "./WhitePaperAnchors/WhitePaperAnchors";
import Technology from "../../component/common/Technology/Technology";
import TokenDistributionTable from "./TokenDistributionTable/TokenDistributionTable";
import VestingScheduleTable from "./VestingSchedulesTable/VestingScheduleTable";
import TabsNFT from "../../component/common/TabsNFT/TabNFT";
import FAQS from "./FAQS/FAQS";

const Whitepaper = () => {
  return (
    <>
      <WhitePaperAnchors />
      <section
        id="whitepaper-introduction"
        className="container numbered-section"
      >
        <h3 className="section-heading">01 Introduction</h3>
        <p className="text-justified">
          NFTX7 is an NFT collection that serves as proof of fractional debt.
          The project aims to invest the cashflow generated from mint sell-outs
          in scalp trading and pay reward to NFT holders in the form of NX7
          tokens, which have a fixed reward value. The token has a deflationary
          mechanism and a built-in volume that serves as the cashflow coming
          from reward payments. NX7 is a transactional token and a peer-to-peer
          means of exchange used to transfer value within the network. Its main
          volume comes from issuers who transfer the financial reward value to
          NFTX7 holders. NX7 is a deflationary ecosystem that maintains its
          value by incentivizing and fueling transactions. Its primary utility
          is to pay NFTX7 holders a fixed-value reward, and token holders can
          utilize it to transfer wealth and value among themselves. The
          ecosystem has a built-in volume that fuels transactions and a
          deflationary model that can mathematically increase its value due to
          the burning effect and the automated market maker model, which defines
          the balancing method for pricing tokens. The process involves NFTX7
          holders staking their NFTs to earn reward and generate passive income.
          Every NFT staked gains $1 worth of NX7 in each staking cycle, which
          lasts for one week (7 days). The NFT does not gain a fixed number of
          tokens but a fixed value of reward. USDT is set aside in a specific
          wallet for reward distribution. After completing a staking cycle, the
          smart contract executes a buying order of $1 for each NFT and
          distributes it immediately to be claimed by the NFT staker. Buying
          orders occur every time an NFT holder completes the staking cycle.
        </p>
      </section>
      <section id="whitepaper-nft" className="container numbered-section">
        <h3 className="section-heading container">02 NFT</h3>
        <TabsNFT />
      </section>
      <section id="whitepaper-technology" className="numbered-section">
        <h3 className="section-heading container">
          03 The technology behind NX7
        </h3>
        <Technology />
      </section>
      <section id="whitepaper-tokenomics" className="container">
        <h3 className="section-heading numbered-section">04 Tokenomics</h3>
      </section>
      <section id="monetary-policy" className="container">
        <h4 className="number-sub-section">4.1 Monetary Policy</h4>
        <p className="text-justified">
          NFTX7 is a cryptocurrency project that uses NFTs to crowd-fund a
          trading portfolio. The project is built around the expected volume
          from its issuers, taking into consideration factors like volatility,
          valuations, and economic behavior. The NX7 token is designed to be
          deflationary, with 1% of tokens burned upon selling and a 1% marketing
          tax to fuel the NFTX7 project. The token's initial liquidity pool is
          deployed on Uniswap (V2) with an initial liquidity of 20,000 USDT and
          200,000 NX7, which determines the initial price of the token at $0.1
          or 0.1 USDT/NX7. As more transactions occur and more holders commit,
          the token becomes less volatile and maintains steady price growth due
          to its deflationary model and buying volumes from the team to
          distribute reward. The team plans to add two more times additional
          liquidity to the pool to have strong liquidity and build financial
          credibility with the community. NX7 will not face devaluation since it
          starts at $0.1 per token and its supply is being reduced. The token
          economics team of NFTX7 will calculate monthly the minimum value the
          token would reach if all the supply is sold and list it for the
          community. The NX7 vesting schedule of locked tokens is designed based
          on the expected volume, and the token has a high circulating supply
          initially since it is a transactional token.
        </p>
      </section>
      <section id="token-utilization" className="container">
        <h4 className="number-sub-section">4.2 Token Utilization</h4>
        <p className="text-justified">
          The NX7 token is designed for usage as a means of payment and is a
          transactional token with a deflationary mechanism. The token is used
          as an reward payment for NFT stakers and can also be used as
          collateral to leverage managed funds. The token appreciates through
          the injection of liquidity from buying turnover from dreward ayments.
          The minimum price reach is calculated by the token economics team. The
          liquidity pool value for NX7 is 20,000 USDT and 200,000 NX7, with an
          initial token price of 0.1$ or 0.1 USDT/NX7. The total supply is
          5,000,000 with a burning fee of 1% on selling volume. The vesting
          schedules range from 0 to 60 months, with the ticker as NX7 and
          decimals set to 5. The selling decimals are set to 3, and the
          capitalization depends on the founder's selloff with an initial market
          capitalization of 223,854.67$ and initial full diluted market
          capitalization of 500,000$. The token is built on the Polygon network
          and will be traded on Uniswap (V2).
        </p>
      </section>
      <section id="token-distribution" className="container">
        <h4 className="number-sub-section">4.3 Token Distribution</h4>
        <TokenDistributionTable />
      </section>
      <section id="vesting-schedules" className="container">
        <h4 className="number-sub-section">4.4 Vesting Schedules</h4>
        <VestingScheduleTable />
      </section>
      <section id="staking-mechanism" className="container">
        <h4 className="number-sub-section">4.5 Staking Mechanism</h4>
        <p className="text-justified">
          NFT holders stake their NFTs on the NFTX7 website to receive profit
          distribution. Only NFT holders who stake their NFTs on the website are
          eligible to receive distributed reward. Stakers receive their dreward
          in profit distribution after a full week of staking their NFTs, known
          as the "Staking Cycle." reward payments are injected as USDT into an
          "reward Distribution Wallet," which is measured by the expected volume
          of reward payment. After completing the "Staking Cycle," one USDT is
          swapped for NX7 tokens for every NFT staked, which is then distributed
          to the NFT staker. The NFT staker can claim their dreward in NX7
          tokens at any time after completing the "Staking Cycle." In general
          staking, stakers gain $1 worth of tokens every week for every NFT
          staked. Stakers earn a fixed value of tokens, not a fixed number of
          tokens.
        </p>
      </section>
      <section
        id="whitepaper-conclusion"
        className="container numbered-section"
      >
        <h3 className="section-heading">05 Conclusion</h3>
        <p className="text-justified">
          NX7 Token is a new NFT project that offers weekly rewards to NFT
          owners in exchange for staking their NFTs. The token is fixed at a
          minting price of $100 and can be traded on Opensea, giving owners an
          opportunity to generate extra profits. The use of NFTs allows for a
          crowd-funded approach to starting a trading portfolio, while the
          deflationary token model ensures that the token's value can appreciate
          over time. In case NFT owners cannot sell their NFT at a higher price,
          they can rely on the creators to repurchase the NFT for its nominal
          value. With NX7 Token, NFT owners can earn rewards while contributing
          to a larger investment strategy.
        </p>
      </section>
      <section id="whitepaper-faqs" className="container numbered-section">
        <h3 className="section-heading">06 FAQS</h3>
        <FAQS />
      </section>
    </>
  );
};

export default Whitepaper;
